const disableScoll = (isPopupOpen) => {
	isPopupOpen ? document.body.style.overlow = "hidden" : document.body.style.overlow = "auto"
}

export default {
	namespaced: true,

	state: () => ({
		showCoursePopup: false,
		currentCourses: null,
		currentTrajectories: null,
		showTransferQuotasPopup: false,
		loadingForm: false,
		loadingTable: false,
		transactionLoading: false
	}),

	mutations: {
		showCoursePopup(state, data) {
			state.showCoursePopup = data.show;
			if (data.courses) state.currentCourses = data.courses;
			if (data.trajectories) state.currentTrajectories = data.trajectories;
			disableScoll(data.show);
		},
		showTransferQuotasPopup(state, data) {
			state.showTransferQuotasPopup = data;
			disableScoll(data);
		},
		setLoadingForm(state, data) {
			state.loadingForm = data;
		},
		setLoadingTable(state, data) {
			state.loadingTable = data;
		},
		setTransactionLoading(state, data) {
			state.transactionLoading = data;
		}
	},
}