import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

export default {
	namespaced: true,

	state: () => ({
		fileReferencesList: [],
		excelReferencesList: null
	}),

	mutations: {
		setFileReferencesList(state, data) {
			state.fileReferencesList = data;
		},
		setExcelReferencesList(state, data) {
			state.excelReferencesList = data;
		},
		deleteReferenceExcel(state, id) {
            state.fileReferencesList
			= state.fileReferencesList
			.filter((profile) => {return profile.id != id});
        },
	},

	actions: {
		getReferencesFilesList({ commit }) {
			axios.get(`/reference_profiles/excel`).then(({ data }) => {
				commit("setFileReferencesList", data)
			})
		},
		loadReferencesExcel({ commit }, data) {
			let request = new FormData()
			request.append("file", data.file)
			axios.post(`/reference_profiles/excel`, request).then(({ data }) => {
				commit("setExcelReferencesList", data);
				notify({
					title: "Файл загружен",
					type: "success"
				})
			})
		},
		async deleteReferenceExcel({ commit }, id) {
            await axios.delete(`/reference_profiles/excel/${id}`)
                .then(() => {
                    commit("deleteReferenceExcel", id)
                    notify({
                        type: "success",
                        title: "Операция прошла успешно"
                    })
                })
        },
	}
}