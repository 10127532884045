import axios from "axios";

export default {
    namespaced: true,

    state: () => ({
        curators: [],
    }),

    mutations: {
        setCurators(state, data) {
            state.curators = data
        },
    },

    getters: {
        curators: state => state.curators
    },

    actions: {
        async getCurators({commit}) {
            await axios.get(`/users/curators`)
                .then(({data}) => {
                    commit("setCurators", data)
                })
        },
    }
}