<template>
  <nav @mouseleave="profileMenuOpen = false">
    <div class="container">
      <div
        class="navbar display--flex justify-content-between align-item-center"
      >
        <div class="flex align-item-center navbar__technical-btns">
          <back-page-btn />
          <div class="navbar__back-cabinet">
            <back-cabinet-btn />
          </div>
        </div>

        <div class="display--flex align-item-center">
          <transition v-if="user && user.design_version == null" name="modal">
            <div class="modal-mask" @click="setVersion(1)">
              <div class="modal-wrapper" >
                <div class="modal-container">
                  <div class="modal-arrow"></div>
                  <div class="modal-content">
                    <p>Мы разработали новый дизайн для этого кабинета.<br> Хотите попробовать?</p>
                    <div class="modal-btn">
                      <button class="btn btn-accent" @click="setVersion(2)">да</button>
                      <button class="btn btn-accent" @click="setVersion(1)">нет</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <div class="panel-body" title="Попробовать новый дизайн">
            <label class="switch">
              <input type="checkbox" @click="setVersion(2)">
              <div class="slider round"></div>
            </label>
          </div>

          <img
            id="specialButton"
            @click="clickOnSpecial"
            class="specialButton"
            style="cursor: pointer"
            src="@/assets/images/icons/eye-icon-w.png"
            alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
            title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ"
          />
          <div v-if="user" class="display--flex align-item-center">
            <ul class="navbar__icons display--flex">
              <li>
                <notification-window :show="showNotifications" />
              </li>
            </ul>
            <button
              @click="profileMenuOpen = !profileMenuOpen"
              class="navbar__profile"
              @mouseenter="winWidth > 768 ? (profileMenuOpen = true) : null"
            >
              <img
                v-if="user.image"
                :src="`${imageUrl}/${user.image}`"
                alt=""
              />
              <span class="navbar__username"
                >{{ user.last_name }} {{ user.first_name }}
                {{ user.patronymic }}</span
              >
              <span class="angle-down"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-column profile-menu" :class="{ open: profileMenuOpen }">
      <router-link class="profile-menu__element" to="/profile"
        ><span>Профиль</span></router-link
      >
      <button @click="logout" class="profile-menu__element">
        <span>Выход</span>
      </button>
    </div>
  </nav>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import {computed, onBeforeMount} from '@vue/runtime-core';
import NotificationWindow from './NotificationWindow.vue';
import { useRoute, useRouter } from 'vue-router';
import BackCabinetBtn from '../Technical/BackCabinetBtn.vue';
import BackPageBtn from './BackPageBtn.vue';
import standartError from "@/mixins/standartError";
export default {
  components: { NotificationWindow, BackCabinetBtn, BackPageBtn },
  name: 'header-component',
  inject: ['imageUrl'],
  setup() {
    const store = useStore(),
      router = useRouter(),
      route = useRoute();
    const profileMenuOpen = ref(false),
      showNotifications = ref(false),
      user = computed(() => store.state.profile.profile),
      winWidth = computed(() => store.state.width);

    const logout = () => {
      store.dispatch('logout');
    };

    const clickOnSpecial = () => {
      document.getElementById('specialButton').click();
    };

    const setVersion = (version) => {
      store
          .dispatch("profile/setDesignVersion", version)
          .then(() => {
            user.value.design_version = version
            if (version === 2) { // New version
              window.location.href = `${process.env.VUE_APP_NEW_CABINET}`;
            }
          })
          .catch();
    };

    return {
      clickOnSpecial,
      profileMenuOpen,
      user,
      winWidth,
      showNotifications,
      router,
      route,
      logout,
      setVersion
    };
  },
};
</script>

<style lang="scss" scoped>
.panel-body {
  margin: 4px 20px 0 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 2px;
  background-color: #eceeef;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3D3D3D;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3D3D3D;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-container {
  position: fixed;
  top: 25px;
  right: 269px;
  width: 300px;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px 0 10px 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
.modal-arrow {
  position: absolute;
  top: 0;
  right: -5px;
  padding: 10px 16px;
  background-color: #fff;
  border-radius: 10px 0 19px 10px;
}

.modal-content {
  color: black;
  text-align: center;
}

.modal-btn {
  padding-top: 10px ;
  display: flex;
  justify-content: space-between;
}

.modal-btn .btn{
  min-width: 100px;
}

.specialButton {
  width: 28px;
  height: 28px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
  filter:opacity(0.65);
  transition: filter 0.2s;
}
 .specialButton:hover {
  filter:opacity(0.8)
  }

.container {
  width: 100%;
  padding: 0 20px;
  margin: auto;
  position: relative;
  background-color: var(--main-color);
  z-index: 4;
}
nav {
  color: rgba(255, 255, 255, 0.5);
  position: fixed;
  z-index: 4;
  top: 0;
  width: 100%;
  right: 0;

  .profile-menu.open {
    top: 61px;
  }
  .profile-menu {
    display: flex;
    position: absolute;
    top: -120px;
    right: 0;
    border-radius: 0 0 3px 3px;
    background-color: #2cc185;
    min-width: 184px;
    z-index: 3;
    box-shadow: 0px 5px 5px -5px rgb(34 60 80 / 60%);
    padding: 0.8462rem 0;
    font-size: 1rem;
    transition: top 0.2s linear;

    span {
      margin-right: 10px;
    }
    &__element {
      color: #fff;
      padding: 9px 20px;
      min-width: 175px;
      text-align: center;
      font-weight: 300;

      &:hover {
        background-color: #fff;
        color: #2cc185;
      }

      &:active {
        color: #2cc185;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0);
      }
    }

    @media (min-width: 767px) {
      right: 13px;
      min-width: 250px;
    }
  }
}

.navbar {
  position: relative;
  height: 62px;
  background-color: #2cc185;

  // .navbar__top-links

  &__top-links {
    -webkit-box-flex: 1;
    -ms-flex: 1 1;
    flex: 1 1;
    flex-wrap: wrap;
  }

  // .navbar__link

  &__link {
    line-height: 62px;
    font-size: 1.077rem;
    padding: 0 18px;
  }

  &__technical-btns {
    margin-left: 285px;
  }

  &__icons {
    margin-right: 5px;
    position: relative;

    button {
      color: rgba(255, 255, 255, 0.5);
      padding: 14px;
      display: block;
    }

    button:hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }

  // .navbar__icon

  &__icon {
    margin-top: 5px;
  }

  // .navbar__profile

  &__profile {
    color: #fff;
    padding: 0 10px 0 18px;
    font-size: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    font-weight: 300;
    z-index: 4;

    img {
      height: 35px;
      width: 35px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  // .navbar__username

  &__username {
    margin-left: 10px;
  }

  @media (max-width: 768px) {
    &__username {
      display: none;
    }

    &__profile {
      padding-left: 5px;
    }

    // &__back-cabinet{
    //   display: none;
    // }

    &__technical-btns {
      margin-left: 275px;
    }

    .angle-down {
      margin-left: 12px;
    }

    .profile-menu {
      right: -30px;
    }
  }

  @media (max-width: 550px) {
    justify-content: flex-end;
  }
}
</style>
