import { notify } from "@kyvg/vue3-notification";

export default (setErrors, clearCustomErrors, isPreloader = null) => (error) => {
    if (error.response && error.response.status == 422) {
        if (isPreloader) isPreloader.value = false
        if(setErrors && clearCustomErrors){
            setErrors(error.response.data);
            clearCustomErrors();
        }
    }
    else {
        if(isPreloader) isPreloader.value = false;
        notify({
            title: "Ошибка",
            type: "error",
            text: error,
        });
    }
}