<template>
  <div
      v-if="showXS"
      class="xs-menu position--absolute display--flex align-item-center"
  >
    <div
        @click="tabletBtn = !tabletBtn"
        class="
        mobile-btn mobile-btn__xs
        display--flex
        align-item-center
        justify-content-center
      "
    >
      <div class="mobile-btn__icon"></div>
    </div>
    <a :href="siteUrl" class="brand display--flex align-item-center">
      <img src="@/assets/images/logo.svg" alt=""/>
    </a>
  </div>
  <aside
      :class="{
      aside__small: showSmallMenu,
      aside__xs: showXS,
      open: showXS && tabletBtn,
    }"
  >
    <div
        v-if="!showXS || (showXS && tabletBtn)"
        class="display--flex align-item-center aside-header"
    >
      <div
          @click="tabletBtn = !tabletBtn"
          class="
          mobile-btn
          display--flex
          align-item-center
          justify-content-center
        "
      >
        <div class="mobile-btn__icon"></div>
      </div>
      <a
          class="brand display--flex align-item-center"
          href="https://way2wei.ru/"
      >
        <img src="@/assets/images/logo-b.svg" alt=""/>
      </a>
    </div>
    <div
        class="navbar-sec display--flex flex-column"
        :class="{ 'navbar-sec__small': showSmallMenu }"
    >
      <ul class="display--flex flex-column">
        <li v-if="getRight('becomePartner')">
          <a
              class="navbar-sec__link green"
              :href="partnersLink"
              target="_blank"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-solid fa-stars"></i>
            </div>
            <span class="navbar-sec__b-text">Стать партнером</span>
          </a>
        </li>
        <li>
          <router-link
              class="navbar-sec__link"
              :class="{ open: openLinkGroup == names.MAIN }"
              to="/"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-cube"></i>
            </div>
            <span>Главная</span>
          </router-link>
        </li>
        <li v-if="getRight('admin')">
          <a
              class="navbar-sec__link complex-link"
              :class="{
              open: openLinkGroup == names.ADMIN,
            }"
              @click="clickOnMenuItem(names.ADMIN)"
              href="#"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-toolbox"></i>
            </div>
            <span> Админка </span>
            <i class="fal fa-angle-down complex-link__arrow"></i>
          </a>
          <transition name="slide-down">
            <ul
                v-if="openSmallLinkGroup === names.ADMIN && !showSmallMenu"
                class="navbar-sec__link-group display--flex flex-column"
            >
              <li>
                <router-link
                    to="/organizations"
                    :class="{ active: selectSmallLinkGroup == names.COMPANIES }"
                >
                  <i class="fad fa-building"></i>

                  <span>Компании</span>
                </router-link>
              </li>
              <li>
                <router-link
                    to="/relations"
                    :class="{ active: selectSmallLinkGroup == names.RELATIONS }"
                >
                  <i class="fad fa-draw-polygon"></i>
                  <span class="navbar-sec__b-text">Связь с сайтом</span>
                </router-link>
              </li>
              <li>
                <router-link
                    to="/whiteListTable"
                    :class="{ active: selectSmallLinkGroup == names.WHITELIST }"
                >
                  <i class="fad fa-draw-polygon"></i>
                  <span class="navbar-sec__b-text">Статистика APIv2</span>
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <router-link
              class="navbar-sec__link"
              :class="{ open: openLinkGroup == names.FAQ }"
              to="/faq"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-question"></i>
            </div>
            <span>FAQ</span>
          </router-link>
        </li>
        <li
            v-if="
            currentExpert?.id || currentPartner?.id || getRight('partnersList')
          "
        >
          <a
              class="navbar-sec__link complex-link"
              :class="{
              open: openLinkGroup == names.PARTNERS_LIST,
            }"
              @click="clickOnMenuItem(names.PARTNERS_LIST)"
              href="#"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-handshake"></i>
            </div>
            <span> Партнерство </span>
            <i class="fal fa-angle-down complex-link__arrow"></i>
          </a>
          <transition name="slide-down">
            <ul
                v-if="
                openSmallLinkGroup === names.PARTNERS_LIST && !showSmallMenu
              "
                class="navbar-sec__link-group display--flex flex-column"
            >
              <li>
                <router-link
                    :class="{ active: selectSmallLinkGroup === names.PARTNER }"
                    :to="getPartnerLink"
                >
                  <i class="fad fa-phone"></i
                  >{{
                    getRight("partnersList") ? "Партнеры" : "Информация"
                  }}
                </router-link
                >
              </li>
              <li>
                <router-link
                    :class="{ active: selectSmallLinkGroup === names.EXPERTS }"
                    to="/experts"
                ><i class="fad fa-head-side-brain"></i>Эксперты
                </router-link
                >
              </li>
              <li>
                <router-link
                    :class="{ active: selectSmallLinkGroup === names.REVIEWS }"
                    to="/reviews"
                ><i class="fad fa-stars"></i>Отзывы
                </router-link
                >
              </li>
            </ul>
          </transition>
        </li>
        <li v-if="getRight('personalList')">
          <a
              class="navbar-sec__link complex-link"
              :class="{
              open: openLinkGroup === names.PERSONAL_LIST,
            }"
              @click="clickOnMenuItem(names.PERSONAL_LIST)"
              href="#"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-chalkboard"></i>
            </div>
            <span> Личное </span>
            <i class="fal fa-angle-down complex-link__arrow"></i>
          </a>
          <transition name="slide-down">
            <ul
                v-if="
                openSmallLinkGroup === names.PERSONAL_LIST && !showSmallMenu
              "
                class="navbar-sec__link-group display--flex flex-column"
            >
              <li>
                <div
                    class="display--flex justify-content-between"
                    :class="{ active: selectSmallLinkGroup === names.COURSES }"
                    v-if="showCourses"
                    @click="isOpenCourses = !isOpenCourses">
                  <a href="#">
                    <i class="fad fa-book-open"></i>
                    <span>Курсы</span>
                  </a>
                  <i class="fal fa-angle-down complex-link__arrow align-self-center"></i>
                </div>
                <transition name="slide-down">
                  <ul v-if="isOpenCourses">
                    <li class="course-list" v-if="certificatesLink">
                      <a
                          :href="certificatesLink"
                          target="_blank"
                      >
                        <i class="fad fa-book-open"></i>
                        <span>Мои сертификаты</span>
                      </a>
                    </li>
                    <li v-for="(course) in courseLinkList.courses" :key="course.external_id" class="course-list">
                      <a
                          :href="course.url"
                          target="_blank"
                      >
                        <span>* {{ course.name }}</span>
                      </a>
                    </li>
                  </ul>
                </transition>
              </li>
              <li>
                <a
                    @mousedown="setNewSimulatorsLink"
                    :class="{ active: selectSmallLinkGroup === names.SIMULATORS }"
                    v-if="simulatorsLink"
                    target="_blank"
                    style="cursor: pointer;"
                >
                  <i class="fad fa-chess-knight"></i>
                  <span>Тренажеры</span>
                </a>
              </li>
              <li v-if="showTests || passingStatus === 'finished'">
                <router-link
                    :class="{ active: selectSmallLinkGroup === names.PASSINGS }"
                    to="/test/passing"
                >
                  <i class="fad fa-address-card"></i>
                  <span>Тестирования</span>
                </router-link>
              </li>
              <li v-else>
                <router-link
                    v-if="passingStatus !== 'finished'"
                    :to="`/passing/${passingCode}`"
                    :class="{ active: selectSmallLinkGroup === names.PASSINGS }"
                >
                  <i class="fad fa-address-card"></i>
                  <span>Тестирование</span>
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li v-if="getRight('companyList')">
          <a
              class="navbar-sec__link complex-link"
              :class="{
              open: openLinkGroup == names.COMPANY_LIST,
            }"
              @click="clickOnMenuItem(names.COMPANY_LIST)"
              href="#"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-building"></i>
            </div>
            <span> Компания </span>
            <i class="fal fa-angle-down complex-link__arrow"></i>
          </a>
          <transition name="slide-down">
            <ul
                v-if="openSmallLinkGroup == names.COMPANY_LIST && !showSmallMenu"
                class="navbar-sec__link-group display--flex flex-column"
            >
              <li>
                <router-link
                    to="/begin"
                    :class="{
                    active: selectSmallLinkGroup === names.BEGIN_WORK,
                    highlight: !isFinishedProgress,
                  }"
                >
                  <i class="fad fa-flag-checkered"></i>
                  <span>Начало работы</span>
                </router-link>
              </li>
              <li>
                <router-link
                    to="/company"
                    :class="{ active: selectSmallLinkGroup === names.COMPANY }"
                >
                  <i class="fad fa-briefcase"></i>
                  <span>Информация</span>
                </router-link>
              </li>
              <li>
                <router-link
                    to="/staff"
                    :class="{ active: selectSmallLinkGroup === names.STAFFS }"
                >
                  <i class="fad fa-phone-office"></i>
                  <span>{{
                      getRight("usersList") ? "Пользователи" : "Сотрудники"
                    }}</span>
                </router-link>
              </li>
              <li v-if="getRight('curators')">
                <router-link
                    to="/curator"
                    :class="{ active: selectSmallLinkGroup === names.CURATORS }"
                >
                  <i class="fad fa-user-check"></i>
                  <span class="navbar-sec__b-text">Кураторы</span>
                </router-link>
              </li>
              <li>
                <router-link
                    to="/group"
                    :class="{ active: selectSmallLinkGroup === names.GROUPS }"
                >
                  <i class="fad fa-users"></i>

                  <span class="navbar-sec__b-text">Работа с сотрудниками</span>
                </router-link>
              </li>

              <li>
                <router-link
                    :class="{ active: selectSmallLinkGroup === names.ANALYTICS }"
                    to="/analytics"
                >
                  <i class="fad fa-books"></i>
                  <span>Аналитика</span></router-link
                >
              </li>
            </ul>
          </transition>
        </li>

        <li v-if="getRight('orders')">
          <router-link
              class="navbar-sec__link"
              to="/orders"
              :class="{ open: openLinkGroup == names.ORDERS }"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-bags-shopping"></i>
            </div>
            <span class="navbar-sec__b-text">Заказы</span>
          </router-link>
        </li>

        <li v-if="getRight('referencesPage') || profile.profile?.id == '15761'">
          <a
              class="navbar-sec__link complex-link"
              :class="{
              open: openLinkGroup == names.REFERENCES,
            }"
              @click="clickOnMenuItem(names.REFERENCES)"
              href="#"
          >
            <div class="display--flex navbar-sec__icon-container">
              <i class="fad fa-books"></i>
            </div>
            <span class="small-text"> Эталонный профиль</span>
            <i class="fal fa-angle-down complex-link__arrow"></i>
          </a>
          <transition name="slide-down">
            <ul
                v-if="openSmallLinkGroup == names.REFERENCES && !showSmallMenu"
                class="navbar-sec__link-group display--flex flex-column"
            >
              <li>
                <router-link
                    to="/references/profiles"
                    :class="{
                    active: selectSmallLinkGroup === names.REFERENCESLIST,
                  }"
                >
                  <i class="fad fa-cubes"></i>
                  <span>Квоты</span>
                </router-link>
              </li>
              <li>
                <router-link
                    to="/references/files"
                    :class="{
                    active: selectSmallLinkGroup === names.REFERENCESFILES,
                  }"
                >
                  <i class="fad fa-file"></i>
                  <span>Загрузка файлов</span>
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import {ref} from "@vue/reactivity";
import {computed, onBeforeMount, watch} from "@vue/runtime-core";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import useCheckRights from "./../../mixins/useCheckRights";
import headerDictionary from "./headerDictionary";

export default {
  name: "header-sec",
  setup() {
    const store = useStore(),
        route = useRoute();
    const openLinkGroup = ref(0),
        tabletBtn = ref(false),
        isOpenCourses = ref(false),
        showCourses = ref(false),
        showTests = ref(false),
        openSmallLinkGroup = ref(0),
        selectSmallLinkGroup = ref(0),
        passingStatus = ref(''),
        passingCode = ref(''),
        {getRight} = useCheckRights();
    const winWidth = computed(() => store.state.width),
        showXS = computed(() => winWidth.value <= 468),
        certificatesLink = computed(() => store.state.courseLink.certificates),
        courseLinkList = computed(() => store.state.courseLink),
        simulatorsLink = computed(() => store.state.simulatorsLink),
        isExternal = computed(() =>
            store.state.user && store.state.user.externalEnter
                ? store.state.user.externalEnter
                : false
        );
    const currentPartner = computed(() => store.state.partners.currentPartner);
    const currentExpert = computed(() => store.state.partners.currentExpert);
    const isFinishedProgress = computed(() => store.state.isFinishedProgress);
    const profile = computed(() => store.state.profile);
    const passings = computed(() => store.state.test.passings);

    const names = headerDictionary;

    const siteUrl = process.env.VUE_APP_SITE;

    const getPartnerLink = computed(() => {
      if (getRight("partnersList")) {
        return "/partners";
      }
      if (currentPartner.value.id) {
        return "/partner";
      }
      return "/expert";
    });

    const showSmallMenu = computed(() => {
      if (
          ((winWidth.value < 768 && !tabletBtn.value) ||
              (winWidth.value >= 768 && tabletBtn.value)) &&
          winWidth.value > 468
      ) {
        if (document.querySelector(".page-container")) {
          document.querySelector(".page-container").style.paddingLeft = "130px";
        }

        return true;
      } else {
        if (document.querySelector(".page-container") && winWidth.value > 768) {
          document.querySelector(".page-container").style.paddingLeft = "300px";
        }
        return false;
      }
    });
    const partnersLink = process.env.VUE_APP_PARTNERS_URL;

    onBeforeMount(() => {
      store.dispatch("getCourseLink");
      store.dispatch("getIspringSimulators");
      store.dispatch("getIsFinishedProgress");
      lightLink();
    });

    function setNewSimulatorsLink() {
      store.dispatch("getIspringSimulators").then(() => {
        window.open(simulatorsLink.value)
      });
    }

    const clickOnMenuItem = (id) => {
      if (showSmallMenu.value) {
        openSmallLinkGroup.value = id;
        tabletBtn.value = !tabletBtn.value;
      } else {
        openSmallLinkGroup.value == id
            ? (openSmallLinkGroup.value = 0)
            : (openSmallLinkGroup.value = id);
      }
    };

    watch(showXS, () => {
      if (document.querySelector(".page-container")) {
        document.querySelector(".page-container").style.paddingLeft = "20px";
      }
    });

    watch(courseLinkList, () => {
      if (courseLinkList.value.courses.length !== 0) {
        showCourses.value = true
      }
    })
    watch(passings, () => {
      if (passings.value.length > 1) {
        showTests.value = true
      } else {
        passingStatus.value = passings.value[0]?.status ? passings.value[0].status : ''
        passingCode.value = passings.value[0]?.code ? passings.value[0].code : ''
      }
    })

    watch(
        () => route.path,
        () => {
          if (winWidth.value < 768) {
            tabletBtn.value = false;
          }
          lightLink();
        }
    );

    const lightLink = () => {
      selectSmallLinkGroup.value = 0;
      switch (route.path) {
        case "/":
          openLinkGroup.value = names.MAIN;
          break;
        case "/begin":
          selectSmallLinkGroup.value = names.BEGIN_WORK;
          openLinkGroup.value = names.COMPANY_LIST;
          break;
        case "/company":
          selectSmallLinkGroup.value = names.COMPANY;
          openLinkGroup.value = names.COMPANY_LIST;
          break;
        case "/staff":
          selectSmallLinkGroup.value = names.STAFFS;
          openLinkGroup.value = names.COMPANY_LIST;
          break;
        case "/curator":
          selectSmallLinkGroup.value = names.CURATORS;
          openLinkGroup.value = names.COMPANY_LIST;
          break;
        case "/group":
          selectSmallLinkGroup.value = names.GROUPS;
          openLinkGroup.value = names.COMPANY_LIST;
          break;
        case "/references":
          selectSmallLinkGroup.value = names.REFERENCES;
          openLinkGroup.value = names.COMPANY_LIST;
          break;
        case "/analytics":
          selectSmallLinkGroup.value = names.ANALYTICS;
          openLinkGroup.value = names.COMPANY_LIST;
          break;
        case "/test/passing":
          selectSmallLinkGroup.value = names.PASSINGS;
          openLinkGroup.value = names.PERSONAL_LIST;
          break;
        case "/relations":
          selectSmallLinkGroup.value = names.RELATIONS;
          openLinkGroup.value = names.ADMIN;
          break;
        case "/organizations":
          selectSmallLinkGroup.value = names.COMPANIES;
          openLinkGroup.value = names.ADMIN;
          break;
        case "/whiteListTable":
          selectSmallLinkGroup.value = names.WHITELIST;
          openLinkGroup.value = names.ADMIN;
          break;
        case "/orders":
          openLinkGroup.value = names.ORDERS;
          break;
        case "/faq":
          openLinkGroup.value = names.FAQ;
          break;
        case "/partners":
        case "/partner":
          selectSmallLinkGroup.value = names.PARTNER;
          openLinkGroup.value = names.PARTNERS_LIST;
          break;
        case "/experts":
          selectSmallLinkGroup.value = names.EXPERTS;
          openLinkGroup.value = names.PARTNERS_LIST;
          break;
        case "/reviews":
          selectSmallLinkGroup.value = names.REVIEWS;
          openLinkGroup.value = names.PARTNERS_LIST;
          break;
        case "/references/profiles":
          selectSmallLinkGroup.value = names.REFERENCESLIST;
          openLinkGroup.value = names.REFERENCES;
          break;
        case "/references/files":
          selectSmallLinkGroup.value = names.REFERENCESFILES;
          openLinkGroup.value = names.REFERENCES;
          break;
        default:
          openLinkGroup.value = 0;
      }
    };

    return {
      openLinkGroup,
      showSmallMenu,
      winWidth,
      tabletBtn,
      showXS,
      openSmallLinkGroup,
      clickOnMenuItem,
      selectSmallLinkGroup,
      certificatesLink,
      courseLinkList,
      lightLink,
      getRight,
      isExternal,
      currentPartner,
      currentExpert,
      getPartnerLink,
      partnersLink,
      names,
      isFinishedProgress,
      siteUrl,
      profile,
      isOpenCourses,
      showCourses,
      simulatorsLink,
      setNewSimulatorsLink,
      showTests,
      passings,
      passingStatus,
      passingCode
    };
  },
};
</script>

<style lang="scss" scoped>
.course-list {
  padding: 5px 0 5px 10px !important;
  border-bottom: 1px #484444 dashed;
}

.course-list a {
  line-height: 1.7 !important;
}

aside {
  z-index: 5;
  background-color: #fff;
  padding: 0 0 70px;
  width: 282px;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease;

  &.aside__small {
    width: 100px;
  }

  &.aside__xs {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  &.open.aside__xs {
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }

  .aside-header {
    height: 62.5px;
    padding-left: 16px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 265px;
    transition: width 0.3s ease 0.001s;
  }

  ul li {
    position: relative;
  }

  @media (max-width: 425px) {
    .aside-header {
      padding-left: 2px;
      background-color: transparent;
      width: 210px;
    }
  }
}

.brand {
  height: 100%;

  &:focus {
    background-color: transparent;
  }

  img {
    height: 56px;
    padding: 20px 17px 7px 35px;
  }
}

.xs-menu {
  top: 0;
  left: 0;
  z-index: 5;

  .brand img {
    padding: 20px 17px 7px 15px;
  }
}

.mobile-btn {
  height: 50px;
  width: 50px;
  margin-left: 5px;
  cursor: pointer;

  &__icon {
    width: 20px;
    background-color: var(--main-color);
    height: 1px;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 20px;
      background-color: var(--main-color);
      height: 1px;
    }

    &::before {
      top: -7px;
    }

    &::after {
      top: 8px;
    }
  }

  &__xs &__icon {
    background-color: #fff;

    &::before,
    &::after {
      background-color: #fff;
    }
  }

  &:hover {
    .mobile-btn__icon {
      background-color: var(--hover-color);

      &::before,
      &::after {
        background-color: var(--hover-color);
      }
    }
  }
}

.navbar-sec {
  margin-top: 63px;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;

  &__icon-container {
    width: 24px;
    align-items: center;
    justify-content: center;
  }

  .small-text {
    font-size: 17px;
  }

  &__link {
    font-size: 1.2rem;
    line-height: 54px;
    min-width: 120px;
    padding: 0 36px;
    text-align: center;
    color: var(--dark-color);
    -webkit-transition: color 0.12s ease-in-out;
    transition: color 0.12s ease-in-out;
    cursor: pointer;

    display: flex;
    align-items: center;

    i {
      margin-right: 15px;
    }

    span {
      padding-left: 5px;
    }

    &:hover {
      background-color: transparent;
      color: var(--hover-color);
    }

    &.green {
      background-color: #00e287;
      border-top: 3px solid white;
      border-bottom: 3px solid white;
    }

    .complex-link__arrow {
      position: absolute;
      left: 85%;
      top: 18px;
    }
  }

  &__b-text {
    text-align: left;
    line-height: 30px;
    padding: 10px 0;
  }

  &__link.admin {
    background-color: #cde2d9;
  }

  &__link.open {
    color: #fff;
    background-color: var(--main-color);

    .complex-link__arrow {
      color: #fff;
    }
  }

  &__link-group {
    width: 100%;
    background-color: #fff;

    li {
      padding-left: 45px;
      padding-right: 27px;
    }

    i {
      margin: 0 6px 0 10px;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      line-height: 50px;
      font-size: 1.077rem;
      color: var(--dark-color);
    }

    a.highlight {
      border-left: 10px solid var(--hover-color);
    }

    a:hover {
      color: var(--main-color);
    }

    a.active {
      color: white;
      background-color: var(--main-color70);
    }
  }

  &__link-group-small {
    position: absolute;
    width: 170px;
    z-index: 4;
    top: 270px;
    left: 90px;
    margin-left: 0;
    background-color: var(--main-color);
    padding: 10px 0;

    a {
      display: block;
      color: white;
      padding: 10px 12px;
    }

    a:hover,
    a.active {
      background-color: white;
      color: var(--main-color);
    }
  }

  &__small {
    .navbar-sec__link {
      flex-direction: column;
      position: relative;

      padding: 0;
      min-width: 0;

      span {
        font-size: 0.7rem;
        line-height: 40px;
      }

      .small-text {
        line-height: 13px;
        padding: 9px 0;
      }

      i {
        margin-right: 0;
        padding-top: 10px;
      }

      .complex-link__arrow {
        display: none;
      }

      &.complex-link::before {
        content: "";
        position: absolute;
        border-bottom: 8px solid var(--main-color);
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        top: -0.5px;
        left: -5px;
        transform: rotate(-45deg);
      }

      .navbar-sec__b-text {
        line-height: 19px;
        text-align: center;
        padding-left: 0;
      }
    }
  }
}

.search {
  position: absolute;
  top: 48px;
  right: 0;
  margin-top: -21px;
  width: 235px;
  border-bottom: 1px solid var(--main-color);

  input {
    border: 1px solid transparent;
    background-color: var(--main-color);
    color: #fff;
    width: 100%;
    font-size: 1.1rem;
    line-height: 1.538;
    padding: 8px 10px;
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: var(--hover-color);
    }

    &::placeholder {
      color: var(--light-color);
    }

    &:-ms-input-placeholder {
      color: var(--light-color);
    }

    &::-ms-input-placeholder {
      color: var(--light-color);
    }
  }

  svg {
    position: absolute;
    color: #fff;
    right: 8px;
    top: 50%;
    margin-top: -12px;
    margin-right: 3px;
  }
}
</style>