import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

export default {
    namespaced: true,

    state: () => ({
        questions: null,
        question: null,
        questionGroup: null,
        questionGroups: null,
        questionType: null,
        questionTypes: null,
        passing: null,
        paddings: null,
        test: null,
        professionList: [],
        cityList: [],
        positionList: [],
        currentPassing: null,
        passingsCount: null,
        codeCurrent: null
    }),

    mutations: {
        setQuestion(state, data) {
            state.question = data
        },
        setQuestions(state, data) {
            state.questions = data
        },
        setQuestionGroup(state, data) {
            state.questionGroup = data
        },
        setQuestionGroups(state, data) {
            state.questionGroups = data
        },
        setQuestionType(state, data) {
            state.questionType = data
        },
        setQuestionTypes(state, data) {
            state.questionTypes = data
        },
        setPassing(state, data) {
            state.passing = data
        },
        setPassings(state, data) {
            state.passings = data
        },
        setTest(state, data) {
            state.test = data
        },
        setProfessionList(state, data) {
            state.professionList = data
        },
        setIndustriesList(state, data) {
            state.industriesList = data
        },
        setPositionList(state, data) {
            state.positionList = data
        },
        setCurrentPassing(state, data) {
            state.currentPassing = data
        },
        setPassingsCount(state, data) {
            state.passingsCount = data
        },
        setCodeCurrent(state, data) {
            state.codeCurrent = data
        }
    },

    actions: {
        async startTest({ commit }, code) {
            await axios.get(`/test/process/${code}`)
                .then(({ data }) => {
                    commit("setTest", data.test)
                    commit("setCurrentPassing", data.passing)
                })
        },
        // eslint-disable-next-line no-unused-vars
        async testStep({ commit }, data) {
            await axios.post(`/test/process/${data.code}`, data.answers)
        },
        // eslint-disable-next-line no-unused-vars
        async sendTestForm({ commit }, data) {
            await axios.post(`/test/process/${data.code}/questionnaire`, data.form)
        },
        // eslint-disable-next-line no-unused-vars
        async createQuestion({ commit }, data) {
            await axios.post(`/test/questions`, data).then(() => {
                notify({
                    title: "Данные сохранены",
                    type: "success"
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        async createQuestionGroup({ commit }, data) {
            await axios.post(`/test/questions/groups/create`, data).then(() => {
                notify({
                    title: "Данные сохранены",
                    type: "success"
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        async createQuestionType({ commit }, data) {
            await axios.post(`/test/questions/types/create`, data).then(() => {
                notify({
                    title: "Данные сохранены",
                    type: "success"
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        async createPassing({ commit }, data) {
            await axios.post(`/test/passings/create`, data).then(() => {
                notify({
                    title: "Данные сохранены",
                    type: "success"
                })
            })
        },
        async getQuestion({ commit }, id) {
            await axios.get(`/test/questions/${id}`)
                .then(({ data }) => {
                    commit("setQuestion", data)
                })

        },
        async getQuestionType({ commit }, id) {
            await axios.get(`/test/questions/types/${id}`)
                .then(({ data }) => {
                    commit("setQuestionType", data)
                })

        },
        async getQuestionGroup({ commit }, id) {
            await axios.get(`/test/questions/groups/${id}`)
                .then(({ data }) => {
                    commit("setQuestionGroup", data)
                })

        },
        async getPassing({ commit }, id) {
            await axios.get(`/test/passings/${id}`)
                .then(({ data }) => {
                    commit("setPassing", data)
                })

        },
        async getQuestions({ commit }) {
            await axios.get(`/test/questions`)
                .then(({ data }) => {
                    commit("setQuestions", data)
                })

        },
        async getQuestionTypes({ commit }) {
            await axios.get(`/test/questions/types`)
                .then(({ data }) => {
                    commit("setQuestionTypes", data)
                })

        },
        async getQuestionGroups({ commit }) {
            await axios.get(`/test/questions/groups`)
                .then(({ data }) => {
                    commit("setQuestionGroups", data)
                })

        },
        async getPassings({ commit }) {
            await axios.get(`/test/passings`)
                .then(({ data }) => {
                    commit("setPassings", data)
                })

        },
        async getProfessionList({ commit }) {
            await axios.get(`/test/process/questionnaire/profession`)
                .then(({ data }) => {
                    commit("setProfessionList", data)
                })

        },
        async getIndustriesList({ commit }) {
            await axios.get(`/test/process/questionnaire/industries`)
                .then(({ data }) => {
                    commit("setIndustriesList", data)
                })

        },
        async getPositionList({ commit }) {
            await axios.get(`/test/process/questionnaire/positions`)
                .then(({ data }) => {
                    commit("setPositionList", data)
                })

        },
        async updateQuestion({ commit }, id, question) {
            await axios.put(`/test/questions/${id}`, question)
                .then(({ data }) => {
                    commit("setQuestion", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async updateQuestionType({ commit }, id, questionType) {
            await axios.put(`/test/questions/${id}`, questionType)
                .then(({ data }) => {
                    commit("setQuestionType", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async updateQuestionGroup({ commit }, id, questionGroup) {
            await axios.put(`/test/questions/${id}`, questionGroup)
                .then(({ data }) => {
                    commit("setQuestionGroup", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async updatePassing({ commit }, id, passing) {
            await axios.put(`/test/questions/${id}`, passing)
                .then(({ data }) => {
                    commit("setPassing", data)
                    notify({
                        title: "Данные сохранены",
                        type: "success"
                    })
                })
        },
        async getPassingsCount({ commit }) {
            await axios.get('/test/passings/count')
                .then(({ data }) => {
                    commit("setPassingsCount", data)
                })
        },
        // eslint-disable-next-line no-unused-vars
        async remindTest({ commit }, code) {
            await axios.post(`/test/passings/${code}/reminder`).then(() => {
                notify({
                    title: "Письмо отправлено",
                    type: "success"
                })
            })
        },
        async getCodeCurrent({ commit }) {
            await axios.get('/test/passings/current')
                .then(({ data }) => {
                    commit("setCodeCurrent", data)
                })
        },
        async getReportLink({ commit }, code) {
            return await axios.get(`/test/passings/${code}/report`)
        }

    }
}