import axios from "axios";

export default function () {
    localStorage.setItem("statusTechnicalWork", '200')
    let currentStatusTechnicalWork = localStorage.getItem("statusTechnicalWork");
    const checkStatusTechnicalWork = () => {
        axios.get(`${process.env.VUE_APP_BACK}/api/healthCheck`)
            .then((data) => {
            if (currentStatusTechnicalWork.toString() !== '200') {
                currentStatusTechnicalWork = data.status;
                return
            }
            if (data.status.toString() !== currentStatusTechnicalWork.toString()) {
                currentStatusTechnicalWork = data.status;
                localStorage.setItem("statusTechnicalWork", data.status)
                location.reload();
            }
        })
            .catch((err) => {
                localStorage.setItem("statusTechnicalWork", err)
        })
    }

    return {checkStatusTechnicalWork}
}