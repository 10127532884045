<template>
  <div
    v-if="(showBack && route.path !== '/company' && route.name !== 'main') || isAnalyticsPage"
    class="back-btn"
    @click="goToPrevPage"
  >
    <i class="fal fa-arrow-left"></i>
  </div>
</template>

<script>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  name: "back-page-btn",
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const showBack = computed(() => store.state.showBack);
		const isAnalyticsPage = computed(() => route.name === 'analytics-report')

		const goToPrevPage = () => {
			if(!isAnalyticsPage.value){
				router.go(-1);
				return;
			}
			router.push("/analytics");
		}

    return {
      showBack,
      route,
			goToPrevPage,
			isAnalyticsPage
    };
  },
};
</script>

<style lang="scss" scoped>
.back-btn {
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 15px 15px 15px 0px;
  cursor: pointer;
  i {
    font-size: 22px;
    margin-right: 7px;
  }

  @media (max-width: 550px) {
    display: none;
  }
}
</style>