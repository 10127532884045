import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import Maska from 'maska'
import Select2 from 'vue3-select2-component';
import Notifications, {notify} from '@kyvg/vue3-notification'
import Popper from "vue3-popper";
import axios from "axios";
import VueYandexMetrika from 'vue3-yandex-metrika'


if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_MOCK === true) {
    const {worker} = require('./mocks/browser')
    worker.start({
        onUnhandledRequest: 'bypass'
    })
}

axios.defaults.baseURL = `${process.env.VUE_APP_BACK}/api`

// Редирект на общую аутентификацию и отлавливание непредвиденных ошибок
axios.interceptors.response.use((response) => response, (error) => {
    if (error.response && error.response.status == 401) {
        store.commit("setRedirectProcess", true)
        window.location.replace(`${process.env.VUE_APP_AUTH}/?site=${window.location}`)
        return Promise.reject(error)
    } else if (!error.response || error.response.status != 422) {
        notify({
            title: "Ошибка",
            text: error.response ? error.response.data.error : error,
            type: "error"
        })
        return Promise.reject(error)
    } else {
        return Promise.reject(error)
    }
})

const VueTelInputOptions = {
    mode: "international",
    onlyCountries: ['RU', 'AL', 'AM', 'AZ', 'BG', 'BY', 'CZ', 'EE', 'GE', 'HU', 'KG', 'KZ', 'LT', 'LV', 'MD', 'ME', 'PL', 'RO', 'TJ', 'TM', 'UZ'],
    preferredCountries: ['RU'],
    autoDefaultCountry: true,
    defaultCountry: 'RU',
    autoFormat: true,
    inputOptions: {
        placeholder: "",
        showDialCode: true,
    },
    validCharactersOnly: true
}

const app = createApp(App)

import {Chart, registerables} from 'chart.js'

Chart.register(...registerables)

app.use(store).use(router).use(VueTelInput, VueTelInputOptions).use(Maska).use(Notifications)
app.use(VueYandexMetrika, {
    id: 61610965,
    router: router,
    env: process.env.NODE_ENV,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
})
app.component('Select2', Select2).component("Popper", Popper)
app.mount("#app");
