<template>
  <div class="notifications">
    <Popper @open:popper="openNotifications" arrow>
      <button class="notifications__notification-btn">
        <i class="fas fa-bell"></i>
      </button>
      <template #content>
        <div class="notifications__notifications-body notifications-body">
          <div class="notifications-body__header flex justify-content-between">
            <div class="notifications-body__title">Уведомления</div>
            <div class="notifications-body__tools">
              <router-link to="/notifications/create" class="btn btn-accent"
                ><i class="fal fa-plus"></i
              ></router-link>
              <router-link
                to="/notifications/options"
                class="btn btn-w"
                alt="Настройки"
                ><i
                  class="fal fa-wrench"
                  data-v-d0a56e52=""
                  data-v-5784ed69-s=""
                ></i
              ></router-link>
            </div>
          </div>

          <div class="notifications-body__area">
            <div
              v-if="!isPreloader"
              class="notifications-body__list flex flex-column w-full"
            >
              <div
                :class="`notifications-body__item flex justify-content-between align-item-center notification-item ${
                  notif.read ? '_read' : ''
                }`"
                v-for="notif in notifications"
                :key="notif.id"
              >
                <div class="notification-item__text">{{ notif.message }}</div>
                <button
                  @click="readNotification(notif.id)"
                  v-if="!notif.read"
                  class="btn"
                >
                  Прочитать
                </button>
              </div>
            </div>
            <div class="notifications-body__preloader table-preloader" v-else>
              <preloader />
            </div>
          </div>
        </div>
      </template>
    </Popper>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import Preloader from "../Technical/Preloader.vue";
export default {
  components: { Preloader },
  name: "notification-window",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const notifications = computed(
      () => store.state.notifications.notificationsList
    );
    const isPreloader = ref(false);
    onBeforeMount(() => {
      isPreloader.value = true;
      store
        .dispatch("notifications/getNotificationsList")
        .then(() => (isPreloader.value = false));
    });
    const openNotifications = () => {
      isPreloader.value = true;
      store
        .dispatch("notifications/getNotificationsList")
        .then(() => (isPreloader.value = false));
    };
    const readNotification = (id) => {
      store.dispatch("notifications/readNotification", { id });
    };

    return {
      openNotifications,
      notifications,
      isPreloader,
      readNotification,
    };
  },
};
</script>

<style lang="scss">
.notifications {
  .popper {
    background-color: white !important;
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.11) !important;
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.11) !important;
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.11) !important;
    color: var(--common-text-color) !important;

    &:hover {
      background-color: white !important;
      color: var(--common-text-color) !important;
    }

    #arrow::before,
    #arrow:hover::before {
      background-color: white !important;
      -webkit-box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
      box-shadow: 0px 5px 10px -3px rgba(34, 60, 80, 0.2);
    }
  }
}
</style>
<style lang="scss" scoped>
.notifications {
  &__notification-btn {
    color: rgba(255, 255, 255, 0.5);
    padding: 14px;
    display: block;

    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }
}

.notifications-body {
  width: 400px;
  height: 450px;
  display: flex;
  flex-direction: column;

  &__header {
    border-bottom: 1px solid var(--cream-color);
  }
  &__title {
    padding: 12px 0 8px 12px;
    font-weight: 300;
  }
  &__tools {
    padding-right: 12px;
    .btn {
      font-weight: 300;
      font-size: 16px;
      height: 40px;
      width: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  &__preloader {
    min-height: 100%;
  }

  &__area {
    margin: 8px;
    background-color: #f9f9f9;
    height: 100%;
    overflow-y: auto;
    -webkit-box-shadow: 5px 5px 12px -5px rgba(34, 60, 80, 0.09) inset;
    -moz-box-shadow: 5px 5px 12px -5px rgba(34, 60, 80, 0.09) inset;
    box-shadow: 5px 5px 12px -5px rgba(34, 60, 80, 0.09) inset;
  }

  @media (max-width: 425px) {
    width: 98vw;
  }
}

.notification-item {
  padding: 10px;
  background-color: var(--main-color);
  color: white;
  border: 1px solid var(--light-color);
  font-weight: 400;

  &__text,
  .btn {
    font-size: 15px;
  }
  .btn {
    padding-left: 4px;
    color: var(--cream-color);
    font-weight: 300;
  }

  &._read {
    .btn {
      color: black;
    }
    &:not(:first-child) {
      border-top: none;
    }
    border: 1px solid var(--light-color);
    background-color: white;
    color: var(--common-text-color);
  }
}
</style>