import { computed } from "vue";
import { useStore } from "vuex"

export default function () {

    const store = useStore();
    const role = computed(() => store.state.profile.profile ? store.state.profile.profile.role : "private_person")
    const role_russian = computed(() => store.state.profile.profile?.role_russian ? store.state.profile.profile?.role_russian : "private_person")

    const rights = {
        "admin": new Set([
            "relations", "orders",
            "references", "expertReferences", "createOrders", "approveOrders", "organizations", "companyEdit", "companyDistribution",
            "mainStatistic", "mainReports", "usersList", "allOrders", "partnersList",
            "companyList", "personalList", "admin", "downloadReports", "beginWork", "referencesPage", "deleteReferences"
        ]),
        "director": new Set([
            "references",
            "companyEdit", "mainStatistic",
            "groupQuota", "orders", "curators", "videoInstruction",
            "companyList", "personalList", "downloadReports", "beginWork", "deleteReferences"
        ]),
        "curator": new Set([
            "references", "mainReports",
            "companyList", "personalList", "downloadReports"
        ]),
        "staff": new Set([
            "mainReports", "personalList"
        ]),
        "private_person": new Set([
            "mainReports", "orders", "personalList"
        ])
    }

    const rightsSuperAdmin = {
        "Суперадмин": new Set(["repurchase"])
    }

    const getRight = (right) => {
        if (role.value)
            return rights[role.value].has(right)
        else return false
    }
    const getRightSuperAdmin = (rightSuperAdmin) => {
        if (role_russian.value)
            return rightsSuperAdmin[role_russian.value].has(rightSuperAdmin)
        else return false
    }

    return {
        role,
        role_russian,
        rights,
        rightsSuperAdmin,
        getRight,
        getRightSuperAdmin,
        
    }


}